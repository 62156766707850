/*jshint browser:true, undef: true, unused: true, jquery: true */


function moveFeaturedCta() {
	'use strict';

	// Move Header Navigation
	$('body.Small, body.Default').each(function () {
		$('#HubWrapper #featured-cta #cta').insertAfter('#HubWrapper #containerWrapper')
	});

	$('body.XL, body.Large, body.Medium').each(function () {
		$('#HubWrapper > #cta').prependTo('#HubWrapper #featured-cta');
	});
}

function filterButton() {
	/* Drop Downs for Mobile (prox nav dropdown) */
	$('#filterSearchWrapper2').on('click', '.FilterSearchButton', function () {
		$('#filterSearchWrapper').slideToggle();
		$(this).toggleClass('Active');
		if($('.FilterSearchButton.Active').length) {
			$('#filterSearchWrapper').attr('aria-hidden', 'false');
			$(this).attr('aria-expanded', 'true');
		} else {
			$('#filterSearchWrapper').attr('aria-hidden', 'true');
			$(this).attr('aria-expanded', 'false');
		}
		return false;
	});
}

function changesOnResize() {
	var windowWidth = Math.max($window.width(), window.innerWidth);
	if (windowWidth >= 1024) {
		$('#filterSearchWrapper').attr('aria-hidden', 'false').removeAttr('style');
	} else {
		if ($('#FilterToggle').attr('aria-expanded') === "false") { 
			$('#filterSearchWrapper').attr('aria-hidden', 'true');
		}
	}
}

(function() {
	"use strict";

	var relatedTermIds = [],
		defaultTermIds = [], // used for resetting term ids array on keyword search
		outputFacetIds = '', //used for pulling in dynamic filters by these other facets
		searchTermIds = '', // used for pulling in dynamic filters (this cannot be an array, otherwise it will not pull in taxonomy only used on pages)

	//all terms should be on each environment since building on dev (removed if url.indexOf statement)
	relatedTermIds = ["4ee27de5-0ec1-e811-a2c8-001dd8b71cdf"];
	defaultTermIds = ["4ee27de5-0ec1-e811-a2c8-001dd8b71cdf"];
	searchTermIds = "4ee27de5-0ec1-e811-a2c8-001dd8b71cdf";
	outputFacetIds = "4ed3b2b3-0e96-442e-aab1-ec79eda95f0a,b6208a4d-ddbd-4352-ab09-6b0dc2179428,2259cf45-fa4b-46c0-92f6-b9f9696c60b8,ce9349e1-1bda-4c59-af2f-e2dedcc42790";

	//relatedTermIds, defaultTermIds & searchTermIds should all be the term id of the Content Hub Page Term under the Content Hub Page facet.
	//outputFacetIds should be the facet ids of the 'Service Lines', 'Content Topic' and 'Content Author' facets.

	var $container,
		$featuredContainer,
		totalRecords = '',
		resultText = '',
		pages,
		filters = {},
		keywordValue = '',
		pageSizeValue = 12, // number of items to pull back on page load and subsequent view more
		sort = 11, //default page sort by date (this will change for keyword search)
		pageNum = 1,
		featuredPageId,
		foundFeaturedPage = false,		
		featuredItem = [],
		items = [],
		itemCount = 0,
		featuredItemCount = 0,	
		getContentType = 'featured';

	//boolean - controls all cookie functionality; if false, cookie will be disabled, not created, not checked
	const useCookie = true;
	const filterCookieName = `blogFilter`;
	const keywordCookieName = `blogKeyword`;
	if (!useCookie) {
		Cookies.remove(filterCookieName);
		Cookies.remove(keywordCookieName);
	}

	// SETTING VARIABLES
	$container = $('#container');
	$featuredContainer = $('#featured');
	
	// ADDING ARIA ATTRIBUTES TO CONTAINER FOR ACCESSIBILITY
	$container.attr('aria-live', 'polite');
	$container.attr('aria-relevant', 'additions removals');

	// SETTING PLACEHOLDER ON CONTENT SEARCH
	$('#ContentSearchField').attr('placeholder', 'Search Health News');
	$('#ContentSearchField').attr('onfocus', 'this.placeholder = ""');
	$('#ContentSearchField').attr('onblur', 'this.placeholder = "Search Health News"');

	// ADDING CONTAINER FOR THE 'FILTERED BY' CONTENT AND SETTING VARIABLE
	$('#filterSearchWrapper2').after('<div id="filter-display"></div>');

	// LOAD FILTER TAXONOMY TERMS TO CREATE THE CHECKBOXES
	loadFilters();
	
	// CHECKING FOR HASH IN URL
	if (window.location.hash) {
		// WAIT TILL FILTERS HAVE LOADED
		var checkExist = setInterval(function() {
			if ($('.CCHNFeaturedExpert .option-set .FilterCloseButton').length > 0) {
				
				var hashValue = window.location.hash.replace('#', ''),
					inputHashElement = hashValue.split('+'),
					inputElement,
					inputElementArray = [];

				// LOOPING THROUGH EACH HASH TAG AS A CLASS ON INPUTS TO SEE IF THEY EXIST AND IF SO ADD THEM TO ARRAY 
				$.each(inputHashElement, function (i, elm) {
					inputElement = '.option-set input.' + elm;

					if ($(inputElement).length > 0) {
						inputElementArray.push(inputElement);
					}
				});

				// TAKE ARRAY OF HASH INPUTS AND CHECK APPROPRIATE CHECKBOXES ON FILTERS & ADD APPROPRIATE TERM IDS TO relatedTermIds ARRAY FOR THE PAGE API & CREATE FILTER CLOSE BUTTONS
				if ($(inputElementArray).length > 0) {
					var checkboxFunction = function() {
						return $.each(inputElementArray, function (i, elm) {
							manageMultipleCheckboxes( $(elm) );
						});
					};
					$.when( checkboxFunction() ).done(function() {
						// AFTER PREVIOUS FUNCTION IS COMPLETE - LOAD FILTERED CONTENT & RUN MASONRY
						getContentType = 'filteredresults';
						pageSizeValue = 12;
						getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
						runAtPageLoad();
						$('#featured-cta').addClass('NoFeatureDisplay');
						$('#cta').appendTo('#containerWrapper');
					});
				}
				clearInterval (checkExist);
			}
		}, 100); // check every 100ms
	} else if (Cookies.get(filterCookieName)) {
		let cookieValue = Cookies.get(filterCookieName);
		cookieValue = cookieValue.split(',');
		cookieValue = cookieValue.map(value => value.split('"')[1]);
		relatedTermIds = cookieValue;//relatedTermIds needs to be an array
		cookieValue = cookieValue.join(',');

		// WAIT TILL FILTERS HAVE LOADED
		let checkExist = setInterval(function() {
			if ($('.CCHNFeaturedExpert .option-set .FilterCloseButton').length > 0) {
				
				let cookieValueArr = cookieValue.split(','),
					inputElement,
					inputElementArray = [];

				// LOOPING THROUGH EACH HASH TAG AS A CLASS ON INPUTS TO SEE IF THEY EXIST AND IF SO ADD THEM TO ARRAY 
				$.each(cookieValueArr, function (i, elm) {
					inputElement = '.option-set input#' + elm;

					if ($(inputElement).length > 0) {
						inputElementArray.push(inputElement);
					}
				});

				// TAKE ARRAY OF HASH INPUTS AND CHECK APPROPRIATE CHECKBOXES ON FILTERS & ADD APPROPRIATE TERM IDS TO relatedTermIds ARRAY FOR THE PAGE API & CREATE FILTER CLOSE BUTTONS
				if ($(inputElementArray).length > 0 || (cookieValueArr.length == 1 && cookieValueArr[0] == defaultTermIds)) {
					let checkboxFunction = () => {
						if ($(inputElementArray).length > 0) {
							return $.each(inputElementArray, function (i, elm) {
								manageMultipleCheckboxes( $(elm) );
							});
						} else if (cookieValueArr.length == 1 && cookieValueArr[0] == defaultTermIds) {
							return (() => {
								manageMultipleCheckboxes( $(`.option-set input#${cookieValueArr[0]}`) );
							})();
						} else {
							console.error(`no cases passed`);
						}
					};
					$.when( checkboxFunction() ).done(function() {
						let keywordCookie = Cookies.get(keywordCookieName);
						if (typeof keywordCookie !== 'undefined' && keywordCookie.length > 0) {
							keywordValue = Cookies.get(keywordCookieName);
						}

						// AFTER PREVIOUS FUNCTION IS COMPLETE - LOAD FILTERED CONTENT & RUN MASONRY
						getContentType = 'filteredresults';
						pageSizeValue = 12;
						getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
						runAtPageLoad();
						$('#featured-cta').addClass('NoFeatureDisplay');
						$('#cta').appendTo('#containerWrapper');
						if (typeof keywordCookie !== 'undefined' && keywordCookie.length > 0) {
							// add filtered by text
							$('#ContentSearchField').val(keywordValue);
							$('#filter-display').prepend('<div id="SearchedBy">You have searched for <span class="Keywords">"' + keywordValue + '"</span></div>');
						}
					});
				}
				clearInterval (checkExist);
			}
		}, 100); // check every 100ms
	} else {
		// LOAD INITIAL CONTENT (FIRST GET CONTENT FOR FEATURED THEN GET All CONTENT (EXCLUDING FEATURED BELOW))
		relatedTermIds = ["1a5979b8-6dd8-e811-a2c9-001dd8b71cdf"]; //featured CCHN term id
		pageSizeValue = 1;
		getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
		
		var checkExist2 = setInterval(function() {
			if ($('#featured .featuredItem').length > 0) {
				relatedTermIds = ["4ee27de5-0ec1-e811-a2c8-001dd8b71cdf"];
				pageSizeValue = 13;
				getContentType = 'allresults',
				getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
				clearInterval(checkExist2);
			}
		}, 100); // check every 100ms

		runAtPageLoad();
	}	

	// RUN ON PAGE LOAD
	//====================================================
	function runAtPageLoad() {
		$(window).ajaxComplete(function () {
			var checkExist = setInterval(function() { //WAITING UNTIL CONTENT EXISTS FROM API 
				if ($('#container .item').length) { //FIND ITEMS 

					$('#TotalResults').remove();
					clearInterval(checkExist);
				}
			}, 100); // check every 100ms
			if(window.location.hash || Cookies.get(filterCookieName)) {
				$('#TotalResults').remove();
				$('#filter-display').after('<div id="TotalResults" aria-live="polite">' + totalRecords + ' Total Results</div>');
			}
		});
	}

	// RUN ON NEW RESULTS (AFTER PAGE LOAD)
	//====================================================
	function runAfterPageLoad() {
		$(window).ajaxComplete(function () {
			var checkExist = setInterval(function() { //WAITING UNTIL CONTENT EXISTS FROM API
				if ($('#container .item').length) {   //FIND ITEMS
					$('#TotalResults, #SearchedBy').remove();
					
					if($('.FilterItem').length > 0) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when filtering by</div>');
					} else if(keywordValue.length > 0) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when searching for <span class="Keywords">"' + keywordValue + '".</span></div>');
					} else {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + '.</div>');
					}
					$('#container').removeAttr('class');
					$('#container').addClass('ItemTotal' + totalRecords);

					clearInterval(checkExist);
				}
			}, 100); // check every 100ms
		});
	}

	// CCHN CONTENT MARKUP
	//====================================================
	var featuredCard = function (page) {
		var element = "<article aria-labelledby='article' class='featuredItem item " + page.expertNoSpace + " " + page.cchnContentTopic + " " + page.serviceLines + " " + page.authorNoSpace + "' id='" + page.pageid + "'>" +
						"<a href='" + page.url + "'>" + 
							"<span class='ImageWrap2'><span class='ImageWrap'>" + page.image + "</span></span>" +
							"<span class='Featured'><span class='FeaturedText'>Featured</span></span>" +
							"<span class='ContentWrap'>" +
								"<span class='Title'>" + page.title + "</span>" +
								"<span class='Description'>" + page.snippet + " <span class='ReadMore'>Read More</span></span>" + 
								page.cchnContentTopicTag +
							"</span>" +
						"</a>" +
					"</article>";
		return element;
	};

	// CCHN CONTENT MARKUP
	//====================================================
	var cards = function (page) {
		var element = "<article aria-labelledby='article' class='item " + page.expertNoSpace + " " + page.cchnContentTopic + " " + page.serviceLines + " " + page.authorNoSpace + "' id='" + page.pageid + "'>" +
						"<a href='" + page.url + "'>" + 
							"<span class='ImageWrap2'><span class='ImageWrap'>" + page.image + "</span></span>" +
							"<span class='ContentWrap'>" +
								"<span class='Title'>" + page.title + "</span>" +
								"<span class='Description'>" + page.snippet + " <span class='ReadMore'>Read More</span></span>" +
								page.cchnContentTopicTag +
							"</span>" +							
						"</a>" +
					"</article";
		return element;
	};

	// BUILD OUT FILTERS DYNAMICALLY AND THEN ALLOW THE DROPDOWNS, FILTERS AND SEARCH TO FUNCTION
	//====================================================
	function loadFilters() {
		const apiPath = `/api/taxonomy/facetandobjects/`;
		const objectTypeCode = 19;
		fetch(`${apiPath}${objectTypeCode}?SearchTermIds=${searchTermIds}&OutputFacetIds=${outputFacetIds}`)
		.then(res => res.json())
		.then(data => {
			buildFilterMarkup(data);
			filterDropdowns();
			filterCheckboxes();
			keywordSearch();
			filterCloseButtons();
			$('.LoadingFilters').remove();
		})
		.catch(console.error);
	}

	// CREATE MARKUP FOR THE FILTERS
	//====================================================
	function buildFilterMarkup(resBody) {
		// the order of the array "facetsToFilter" determines the order they are output to the DOM
		const facetsToFilter = ['CCHN Topic', 'Service Line', 'CCHN Author', 'CCHN Featured Expert'];
		if (resBody.Facets.length > 0) {
			let facetMarkup = resBody.Facets
			.filter(facet => facetsToFilter.includes(facet.FacetName))
			.map(facet => ({
				name: facet.FacetName,
				html: buildFacetMarkup(facet)
			}));

			let html = `` +
			`<div id="filter">` +
				`<div id="options">` +
					`<p>Filter by:</p>` +
					`<div id="filter-groups" class="ClearFix">` +
			``;

			// the order of the array "facetsToFilter" determines the order they are output to the DOM
			facetsToFilter.forEach(facetName => {
				facetMarkup.forEach(facetObject => {
					if (facetObject.name === facetName) {
						html += facetObject.html;
					}
				});
			});

			html += `` +
					`</div>` +
				`</div>` +
			`</div>` +
			`<div id="ContentSearchWrapper">` +
				`<div id="Or">or</div>` +
				`<div id="ContentSearch">` +
					`<label class="Hide" for="ContentSearchField">Search Health News</label>` +
					`<input id="ContentSearchField" maxlength="128" name="term" type="search" value="" placeholder="Search Health News" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search Health News'">` +
					`<input alt="Search" class="ImgButton" src="/imgs/icons/search_blue.svg" type="image">` +
				`</div>` +
			`</div>`;
			
			let queryFilterSearch = document.querySelector('#filterSearchWrapper');
			queryFilterSearch.innerHTML += html;
			// queryFilterSearch.parentElement.innerHTML += `<div id="filter-display" class="ClearFix"></div>`;
		} else {
			console.info(`Content Hub - no facets found`);
		}
	}

	// CREATE INDIVIDUAL FACET MARKUP FOR THE FILTERS
	//====================================================
	function buildFacetMarkup(facet) {
		let facetDisplayName, facetDisplayNamePlural;
		switch(facet.FacetPropertyName) {
			case 'CCHNTopic':
				facetDisplayName = 'Topics';
				facetDisplayNamePlural = 'Topics';
				break;
			case 'ServiceLine':
				facetDisplayName = 'Services';
				facetDisplayNamePlural = 'Services';
				break;
			case 'CCHNAuthor':
				facetDisplayName = 'Authors';
				facetDisplayNamePlural = 'Authors';
				break;
			case 'CCHNFeaturedExpert':
				facetDisplayName = 'Experts';
				facetDisplayNamePlural = 'Experts';
				break;
			default:
				break;
		}

		let termsMarkup = ``;
		if (facet.Terms.length > 0) {
			termsMarkup = buildTermsMarkup(facet, facetDisplayNamePlural);
		}

		const hiddenFilters = ['CCHNAuthor', 'CCHNFeaturedExpert'];
		return `` +
		`${hiddenFilters.includes(facet.FacetPropertyName) ? `<div style="display:none;">` : ``}` +
		`${['ServiceLine', 'CCHNAuthor', 'CCHNFeaturedExpert'].includes(facet.FacetPropertyName) ? `<p>and/or</p>` : ``}` +
		`<div class="${facet.FacetPropertyName}">` +
			`<button class="FilterButton" id="${facet.FacetPropertyName}">${facetDisplayName}<span></span></button>` +
			termsMarkup +
		`</div>` +
		`${hiddenFilters.includes(facet.FacetPropertyName) ? `</div>` : ``}` + ``;
	}

	// CREATE INDIVIDUAL TERM MARKUP FOR THE FACET FILTERS
	//====================================================
	function buildTermsMarkup(facet, pluralName) {
		let html = `` +
		`<fieldset class="option-set" data-group="${facet.FacetPropertyName}">` +
			`<legend class="option-set-header ClearFix">${pluralName}</legend>` +
			`<div class="option-set-contents">` +
				`<label for="${facet.FacetPropertyName}-all"><input checked="checked" class="all" id="${facet.FacetPropertyName}-all" type="checkbox" value=""><span class="checkmark"></span> All ${pluralName}</label>` +
		``;

		facet.Terms.forEach(term => {
			if (term.Name != "_No Topic") {
				let termNameClass = term.Name.replace(/[^0-9a-zA-Z]+/g, '');
				html += `<label for="${term.TermId}" ${term.Distance !== 0 ? 'class="ChildTerm"' : ''}><input id="${term.TermId}" class="${termNameClass}" type="checkbox" value="${term.TermId}" /><span class="checkmark"></span> ${term.Name}</label>`;
			}
		});

		html += `` +
			`</div>` +
			`<button class="FilterCloseButton">Close</button>` +
		`</fieldset>` +
		`<div class="FadeOverlay"></div>` +
		``;

		return html;
	}

	// DROP DOWN BUTTON FUNCTIONALITY
	//====================================================
	function filterDropdowns() {
		// logBold(`filterDropdowns()`);
		$('#options button.FilterButton').on('click', function(e) {
			e.preventDefault();
			if($(this).hasClass('Active')) {
				//remove active class to close dropdown on clicked button if was active
				$(this).removeClass('Active');
				$(this).next('.option-set').removeClass('Active');
				$('body').removeClass('NoScroll');
				$('.modal-overlay').remove();
				if($('.stick-it #options button.FilterButton').length > 0) {
					$('.stick-it').removeClass('stick-it-full-height');
				}
			} else {
				//first remove active class from all in case other drop down is open
				$('#options button.FilterButton').removeClass('Active');
				$('#options button.FilterButton').next('.option-set').removeClass('Active');
				//then open clicked on button dropdown
				$(this).addClass('Active');
				$(this).next('.option-set').addClass('Active');
				$('body').addClass('NoScroll');
				$('.modal-overlay').remove();
				$('<div class="modal-overlay"></div>').insertBefore('#filter');
				if($('.stick-it #options button.FilterButton').length > 0) {
					$('.stick-it').addClass('stick-it-full-height');
				}
				if ($('.stick-it #options button.FilterButton').length > 0) {
					$('html, body').animate({
						scrollTop: $('#containerWrap').offset().top
					}, 1000);
				}
				activateFadeOverlay();
			}
		});
	}

	// DO STUFF ON CHECKBOX CHANGE
	//====================================================
	function filterCheckboxes() {
		$('#options').on( 'change', function( jQEvent ) {
			var $checkbox = $( jQEvent.target );

			//first check to see if .items are available before clearing to know whether it needs masonry on page load or after page load
			var newPageLoad = true;
			if ($('#container .item').length > 0) {
				newPageLoad = false;
			}
			
			//THEN CLEAR OUT ELEMENTS IF THERE
			resetPageElements();
			
			//MANAGING THE CHECKBOXES AND SET NEW VALUES
			manageCheckbox( $checkbox );

			// LOAD NEW RESULTS BASED ON TERMS AND APPLY MASONRY
			$('input#ContentSearchField').val('');
			keywordValue = ''; //resetting keyword value to be empty so that it doesn't combine the search funcionalities
			sort = 11; //resetting sort value to be based on date
			pageSizeValue = 12;
			getContentType = 'filteredresults';
			manageCookies(keywordCookieName, keywordValue);
			getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
			if (newPageLoad) {
				runAtPageLoad();
			} else {
				runAfterPageLoad();
			}
			$('#featured-cta').addClass('NoFeatureDisplay');
			$('#cta').appendTo('#containerWrapper');
		});
	}

	// DO STUFF ON FILTERED BY BUTTONS TO TURN OFF FILTERS (BY FORCING A CLICK ON ASSOCIATED INPUT IT WILL RUN THE filterCheckboxes FUNCTION)
	//====================================================
	function filterCloseButtons() {
		$('.CCHN').on('click', '#filter-display button.FilterItem, #filter-display button input', function(e) {
			e.preventDefault();
			$('.option-set input').each(function() {
				if ($(this).attr('value') === $(e.target).attr('data-id')) {
					$(this).trigger('click');
				}
			});
		});
	}

	// DO STUFF ON KEYWORD SEARCH
	//====================================================
	function keywordSearch() {
		// logBold(`keywordSearch()`);	
		$('input#ContentSearchField').each(function () {
			$(this).on('focus', function() {
				keywordValue = $(this).val();
			});
			$(this).on('blur', function() {
				keywordValue = $(this).val();
			});
			// keyword search on enter key
			$(this, 'input.ImgButton').keydown(function(event) {
				if(event.keyCode === 13) {
					event.preventDefault();
					keywordSearchFunc();
				}
			});
			// keyword search on button click
			$('#ContentSearch input.ImgButton').click(function(event) {
				event.preventDefault();
				keywordSearchFunc();
			});
		});
	}
	function keywordSearchFunc() {
		//setting the error text value
		var emptySearch = '<div class="error">Please enter search criteria.</div>';
		//setting sort order to go by relevancy
		sort = 7; 
		//setting the keyword value to what's within the input
		keywordValue = $('input#ContentSearchField').val(); 
		//resetting the relatedTermIds back to the default content type facet terms
		relatedTermIds = defaultTermIds.slice();

		if (keywordValue.length > 0) {
			// remove contents, error message, filtered by buttons, and checkmarks from filters (add checked to all checkmarks)
			resetPageElements();
			$('button.FilterItem').remove();
			$('.option-set input').not('.option-set input.all').prop('checked', false);
			$('.option-set input.all').prop('checked', true);

			// add filtered by text
			$('#filter-display').prepend('<div id="SearchedBy">You have searched for <span class="Keywords">"' + keywordValue + '"</span></div>');
			// pull back content by keyword
			getContentType = 'filteredresults';
			pageSizeValue = 12;
			manageCookies(filterCookieName, relatedTermIds);
			manageCookies(keywordCookieName, keywordValue);
			getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
			// run on content
			runAfterPageLoad();
			$('#featured-cta').addClass('NoFeatureDisplay');
			$('#cta').appendTo('#containerWrapper');
		} else {
			if (!$('#ContentSearch .error').length) {
				$('#ContentSearch').append(emptySearch);
			}
		}
	}

	// PULL IN CONTENT FROM PAGE API - THIS GETS CALLED ON PAGE LOAD, ON KEYWORD SEARCH & ON CHECKBOX FILTERS
	//====================================================
	function getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum ) {
		// logBold(`getContent()`);
		$('#ViewMore, #TotalResults').remove();
				
		$.getCmsPages({
			pageNumber: pageNum,
			pageSize: pageSizeValue,
			sort: sort,
			fields  : "PageId, Name, publichref, originalPublicationDate, Description, Images, Taxonomy",
			criteria: {
				originalpublicationbegindate: "01/01/2013",
				relatedTermIds: relatedTermIds,
				keyword: keywordValue
			}
		}, function (data) {
			//store totalRecords in variable
			totalRecords = data.TotalRecords;
			pages = Math.ceil(totalRecords / pageSizeValue);

			for (var i in data.Values) {
				var cmspage = data.Values[i];

				var item = {
					title: cmspage.Name,
					pageid: cmspage.PageId,
					url: cmspage.PublicHRef.replace(/~/g, ''),
					newDateFormat: buildPubDate(cmspage),
					snippet: buildSnippet(cmspage),
					imageSrc: (typeof cmspage.Images[0] !== 'undefined') ? "/app/files/public/" + cmspage.Images[0].FileName : "/imgs/CCHN-Thumb.jpg",
					image: (typeof cmspage.Images[0] !== 'undefined') ? "<img alt='' src='/app/files/public/" + cmspage.Images[0].FileName + "'/>" : "<img alt='' src='/imgs/CCHN-Thumb.jpg' />",
					cchnContentTopic: '',
					eachTopicTermWithNobr: '',
					cchnContentTopicTag: '',
					serviceLines: '',
					expertName: '',
					authorName: '',
					expertNoSpace: '',
					authorNoSpace: '',
					expertLink: '',
					authorLink: '',
					beforeAuthorDivider: '',
					topicTerms: [],
					serviceTerms: [],
					//(this array will include anchor + name with markup)
					expertTerms: [],
					authorTerms: [],
					//declare author names with no space array (this array will inlude each authors name with no space except between names - can be used as class names on layout)
					expertNamesNoSpace: [],
					authorNamesNoSpace: [],
				};

				for (i in cmspage.Taxonomy) {
					//set variable to taxonomyy object
					var Taxonomy = cmspage.Taxonomy[i];

					//check for taxonomy = CCHN Topic
					if (Taxonomy.FacetName === 'CCHN Topic') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var topicTerm = Taxonomy.Terms[i];
							//add terms to array
							item.topicTerms.push(topicTerm.Name);
						}
						item.cchnContentTopic = (item.topicTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
						// the cchnContentTopicTag is being used at the top of the cards over the image if image is available 
						//(this takes topics from taxonomy and if it's not undefined & it's not marked "No Topic" then set any others to this variable), also add in comma between each Topic term
						item.eachTopicTermWithNobr = "<span class='nobr'>" + (item.topicTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
						item.cchnContentTopicTag = (item.topicTerms.toString() !== 'undefined' && item.topicTerms.toString() !== '_No Topic') ? "<span class='Topic'>" + item.eachTopicTermWithNobr + "</span>" : "";
					}

					//check for taxonomy = Service Line
					if (Taxonomy.FacetName === 'Service Line') {
						//loop through taxonomy for terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object
							var serviceTerm = Taxonomy.Terms[i];
							//add terms to array
							item.serviceTerms.push(serviceTerm.Name);
						}
						item.serviceLines = (item.serviceTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
					}

					//check for taxonomy = CCHN Author
					if (Taxonomy.FacetName === 'CCHN Author') {
						//loop through taxonomy for specific facet terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object (authorName is each CCHN Author term.Name)
							item.authorName = Taxonomy.Terms[i].Name;
							//if any author names exist then assign beforeAuthorDivider this markup (added with variable in CCHN content markup)
							if(item.authorName.length > 0) {
								item.beforeAuthorDivider = '<span class="ContentTopBorder"></span>';
							}
							//set variable for each authorName to remove all spaces and special characters
							item.authorNoSpace = (item.authorName.toString().replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g,''));
							item.authorLink = " <a class='Author' href='#" + item.authorNoSpace + "'>" + item.authorName + "</a>";

							item.authorTerms.push(item.authorLink);
							item.authorNamesNoSpace.push(item.authorNoSpace);
						}

						item.authorLink = (item.authorTerms.toString());
						item.authorNoSpace = (item.authorNamesNoSpace.toString());
					}

					//check for taxonomy = CCHN Featured Expert
					if (Taxonomy.FacetName === 'CCHN Featured Expert') {
						//loop through taxonomy for specific facet terms
						for (i in Taxonomy.Terms) {
							//set variable to terms object (authorName is each CCHN Author term.Name)
							item.expertName = Taxonomy.Terms[i].Name;

							//set variable for each authorName to remove all spaces and special characters
							item.expertNoSpace = (item.expertName.toString().replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g,''));
							item.expertLink = " <a class='Expert' href='#" + item.expertNoSpace + "'>" + item.expertName + "</a>";

							item.expertTerms.push(item.expertLink);
							item.expertNamesNoSpace.push(item.expertNoSpace);
						}
						item.expertLink = (item.expertTerms.toString());
						item.expertNoSpace = (item.expertNamesNoSpace.toString());
					}
				} //end taxonomy loop

				//IF THERE ARE RESULTS & THEY ARE FEATURED RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'featured') {
					featuredItem.push(item);

					for (featuredItemCount; featuredItemCount < pageSizeValue*pageNum && featuredItemCount < featuredItem.length; featuredItemCount++) {
						var featuredcardsData = featuredCard(featuredItem[featuredItemCount]);
						$featuredContainer.append (featuredcardsData);
					}
				}

				//IF THERE ARE RESULTS & THEY ARE ADDITIONAL RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'allresults') {
					featuredPageId = $('.featuredItem').attr('id');
					if (item.pageid != featuredPageId) {
						items.push(item);
					} else {
						foundFeaturedPage = true;
					}

					//IF THERE ARE RESULTS THEN BUILD OUT BASED ON CARDS DATA
					for (itemCount; itemCount < 12*pageNum && itemCount < items.length; itemCount++) {
						//console.log(itemCount + " " + pageSizeValue);
						var cardsData = cards(items[itemCount]);
						$container.append (cardsData);
					}

					//IF WE HAVE A FEATURED IMAGE THEN REMOVE ONE FROM THE TOTAL RECORDS BELOW TO DIVIDE BY PAGE SIZE VALUE
					if (foundFeaturedPage) {
						pages = Math.ceil((totalRecords - 1) / pageSizeValue);
					} else {
						pages = Math.ceil(totalRecords / pageSizeValue);
					}
				}

				//IF THERE ARE RESULTS & THEY ARE ADDITIONAL RESULTS THEN BUILD OUT BASED ON CARDS DATA
				if (data.Values.length > 0 && getContentType === 'filteredresults') {
					var cardResultsData = cards(item);
					$container.append (cardResultsData);
				}
			}

			if (getContentType === 'allresults' || getContentType === 'filteredresults') {
				//IF THERE ARE NO RESULTS THEN GIVE THE NO RESULTS MESSAGE
				if (totalRecords === 0) {
					$('#filter-display').append ('<div class="NoResults"><p>Sorry, no results were found.</p></div>');
				}

				//ADDING THE VIEW MORE BUTTON IF MORE THAN SET NUMBER OF RESULTS & FUNCTIONALITY OF VIEW MORE BUTTON
				if (pageNum < pages && pages > 1) {
					$('#containerWrap').append('<div id="ViewMore"><button>More Stories</button></div>');
					$('#ViewMore button').click(function () {
						pageNum++;
						getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
						runAfterPageLoad();
					});
				}
			}

			$('.Loading').remove();
		});
	}

	// MANAGE THE CHECKBOXES (IF LAST CHECKBOX IS UNCHECKED THEN AUTO CHECK THE ALL CHECKBOX OR IF ALL IS SELECTED UNCHECK ALL OTHER CHECKBOXES) 
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageCheckbox( $checkbox ) {
		// logBold(`manageCheckbox()`);
		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCBLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[ group ];
		if ( !filterGroup ) {
			filterGroup = filters[ group ] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)
		var index = $.inArray( checkbox.value, filterGroup );

		// CHECKING THE ALL CHECKBOX WITHIN A GROUP
		if ( isAll ) {

			//remove all filters from the group
			delete filters[ group ];

			//if checkmark is not checked then check it
			if ( !isAll.checked ) {
				checkbox.checked = 'checked';
			}

			//removing all other related terms from the group from the relatedTermIds criteria to pull back content
			relatedTermIds = relatedTermIds.filter( function( el ) {
				return filterGroup.indexOf( el ) < 0;
			} );

			manageCookies(filterCookieName, relatedTermIds);

			//remove filtered by buttons for individual group when you check all
			$('button.FilterItem').each(function() {
				if ($.inArray($(this).attr('data-id'), filterGroup) !== -1) {
					$(this).remove();
				}
			});
		}

		// CHECKING ANY CHECKBOX WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		if ( checkbox.checked ) {
			var selector = isAll ? 'input' : 'input.all';

			//remove check from all checkbox
			$(otherCBLabelsInGroup).children( selector ).removeAttr('checked');

			if ( !isAll && index === -1 ) {
				// add filter to group
				filters[ group ].push( checkbox.value );
				relatedTermIds.push( checkbox.value );
				manageCookies(filterCookieName, relatedTermIds);

				//build out filtered by area
				$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
			}
		}

		// UNCHECKING A CHECKMARK WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		else if ( !isAll ) {
			// remove filter from group
			filters[ group ].splice( index, 1 );
			if (( index = relatedTermIds.indexOf( checkbox.value )) !== -1) {
				relatedTermIds.splice( index, 1 );
				manageCookies(filterCookieName, relatedTermIds);
			}
			// if unchecked the last box, check the all
			if ( !$(otherCBLabelsInGroup).children().is( ":checked" ) ) {
				$(otherCBLabelsInGroup).children('input.all').attr('checked', 'checked');
			}
			//removing filtered by buttons when unchecked from drop down
			$('button.FilterItem').each(function() {
				if ($(this).attr('data-id') === checkbox.value) {
					$(this).remove();
				}
			});
		}
	}
	
	// MANAGE MULTIPLE CHECKBOXES (THIS OCCURS WHEN HASH TAGS ARE ADDED TO THE URL) 
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageMultipleCheckboxes( $checkbox ) {
		// logBold(`manageMultipleCheckboxes()`);
		// console.log($checkbox);
		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCBLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[ group ];
		if ( !filterGroup ) {
			filterGroup = filters[ group ] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)

		let index;
		if ($checkbox.length > 0) {
			index = $.inArray( checkbox.value, filterGroup );
		}

		manageCookies(filterCookieName, relatedTermIds);

		var selector = isAll ? 'input' : 'input.all';
		//since there is not click we need to force the active checked state
		$checkbox.attr('checked', 'checked');
		
		//remove check from all checkbox
		$(otherCBLabelsInGroup).children( selector ).removeAttr('checked');

		if ( !isAll && index === -1 ) {
			// add filter to group
			filters[ group ].push( checkbox.value );
			//only push to array if it doesn't already have the value, such as when loading from cookie
			if (!relatedTermIds.includes(checkbox.value)) {
				relatedTermIds.push( checkbox.value );
				manageCookies(filterCookieName, relatedTermIds);
			}
			//build out filtered by area
			$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
		}	
	}

	// returns an error message if a developer tries to pass invalid arguments when invoking
	// function validateCookieArguments(argOne, argTwo) {
	// 	return new Promise((resolve, reject) => {

	// 		logBold(`validateCookieArguments()`);

	// 		const validFirstArguments = ['add', 'remove', 'destruct'];
	// 		const validSecondArguments = ['all'];

	// 		// handler to determine if the first argument is valid
	// 		function firstArgIsInvalid(arg, arrValid) {
	// 			let returnVal = false;
	// 			let notMatchArr = false;
	// 			let errMessage = '';

	// 			if (typeof arg !== 'string') {
	// 				returnVal = true;
	// 			}
	// 			if (!arrValid.includes(arg)) {
	// 				returnVal = true;
	// 				notMatchArr = true;
	// 			}

	// 			if (notMatchArr === false) {
	// 				if (returnVal) {
	// 					errMessage = `* manageCookies() > validateCookieArguments() > firstArgIsInvalid()\nargument ${arg} is not of type "string"`;
	// 				}
	// 				return returnVal;
	// 			} else {
	// 				if (returnVal) {
	// 					errMessage = `* manageCookies() > validateCookieArguments() > firstArgIsInvalid()\nargument "${arg}" is not an accepted value, accepted values are: ${arrValid.map(item => `"${item}"`).join(', ')}`;
	// 				}
	// 				return {value: returnVal, err: errMessage};
	// 			}
	// 		}

	// 		// handler to determine if the second argument is valid
	// 		function secondArgIsInvalid(arg, arrValid) {
	// 			let returnVal = false;
	// 			let notMatchArr = false;
	// 			let notMatchGuid = false;
	// 			let errMessage = '';

	// 			if (typeof arg !== 'string') {
	// 				returnVal = true;
	// 			}
	// 			if (!arrValid.includes(arg)) {
	// 				returnVal = true;
	// 				notMatchArr = true;
	// 			}
	// 			if (!/(\w|\d){8}-(\w|\d){4}-(\w|\d){4}-(\w|\d){4}-(\w|\d){12}\b/g.test(arg)) {
	// 				returnVal = true;
	// 				notMatchGuid = true;
	// 			}

	// 			if (notMatchArr === false && notMatchGuid === false) {
	// 				if (returnVal) {
	// 					errMessage = `* manageCookies() > validateCookieArguments() > secondArgIsInvalid()\nargument ${arg} is not of type "string"`;
	// 				}
	// 				return returnVal;
	// 			} else if (notMatchArr === true && notMatchGuid === false) {
	// 				returnVal = false;
	// 				return returnVal;
	// 			} else if (notMatchArr === false && notMatchGuid === true) {
	// 				returnVal = false;
	// 				return returnVal;
	// 			} else {
	// 				if (returnVal) {
	// 					errMessage = `* manageCookies() > validateCookieArguments() > secondArgIsInvalid()\nargument "${arg}" is not an accepted value, accepted values are: ${arrValid.map(item => `"${item}"`).join(', ')} or a guid matching the Regex pattern`;
	// 				}
	// 				return {value: returnVal, err: errMessage};
	// 			}
	// 		}

	// 		// test that first arg action is valid
	// 		const firstArgIsInvalidResult = firstArgIsInvalid(argOne, validFirstArguments);
	// 		if (firstArgIsInvalidResult.value) {
	// 			reject(new Error(firstArgIsInvalidResult.err));
	// 		}

	// 		// test that second arg termId is valid
	// 		const secondArgIsInvalidResult = secondArgIsInvalid(argTwo, validSecondArguments);
	// 		if (secondArgIsInvalidResult.value) {
	// 			reject(new Error(secondArgIsInvalidResult.err));
	// 		}

	// 		resolve(true);
	// 	});
	// }

	// manage the logic of the cookie so that filters can be applied if a user selects "back"
	function manageCookies(cookie, value) {
		if (useCookie) {

			if ([filterCookieName, keywordCookieName].includes(cookie)) {
				let expiry = new Date();
				expiry.setHours(23, 59, 59, 0);
				Cookies.set(cookie, value, {expires: expiry});
			} else {
				console.error(`invalid cookie name`);
			}
		}
	}

	// ON RESIZE - ACTIVATE FADE OVERLAY ON FILTER LIGHTBOX FUNCTION
	//===================================================
	$(window).resize(function () {
		activateFadeOverlay();
	});

	//ADDING CLASS TO FADE OVERLAY ON FILTER LIGHTBOX ONLY WHEN SCROLL BAR IS THERE
	//====================================================
	function activateFadeOverlay() {
		if ($('.option-set.Active').length > 0) {
			var activeOptionSet = ($('.option-set.Active').outerHeight());
			var activeOptionSetContents = ($('.option-set.Active .option-set-header').outerHeight() + $('.option-set.Active .option-set-contents').outerHeight() + 3);
			
			if (activeOptionSet < activeOptionSetContents) {
				$('.FadeOverlay').addClass('Active');
			}
			else {
				$('.FadeOverlay').removeClass('Active');
			}
		}
	}
	
	//SEARCH OR FILTER RESETS - REMOVING OR UPDATING ELEMENTS WHEN NEW SEARCH OR FILTER OCCURS
	//====================================================
	function resetPageElements() {
		$('#SearchedBy, .NoResults, #ContentSearch .error, #containerWrap #ViewMore, .featuredItem').remove();
		$container.find('.item').remove();
		$container.css('height', 'auto');
	}

	// ANY CLICK OUTSIDE OF THE FILTERS LIGHTBOX AREA WILL CLOSE AN ACTIVE FILTER OPTIONS BOX
	//====================================================
	$(document).on('click', function (e) { 
		if ($('#filter').has(e.target).length === 0) {
			$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
		}
	});

	// CLICKING ON THE X ON THE FILTER BOX WILL CLOSE ACTIVE FILTER OPTIONS BOX
	//====================================================
	$('.CCHN').on('click', 'button.FilterCloseButton', function (ele) { 
		ele.preventDefault();
		$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
	});

	// CLICKING ON AUTHORS WITHIN THE ITEM CONTENT BLOCKS 
	//(since it stays on the same page we need to clear any previous filter and trigger the filter input, because it doesn't reload the page)
	//====================================================
	$('.item a.Author').on('click', function () { 
		var authorHashValue = $(this).attr('href').replace('#', ''),
			inputHashElement = '.option-set input.' + authorHashValue;
		if (!$(inputHashElement).is(':checked')) {
			$(inputHashElement).trigger('click');
		}
	});

	// BUILD OUT PAGE SNIPPET WITHIN API
	//====================================================
	function buildSnippet(cmspage) {
		var pageContent = cmspage.Description,
			pageParagraphContent = pageContent.substring(pageContent.indexOf("<p>")),
			pageContentText = pageParagraphContent.toString().replace(/(<([^>]+)>)/ig, "");

		return pageContentText.split(/\s+/).slice(0,21).join(" ")+'<span>... </span>';
	}

	// BUILD OUT PUB DATE
	//====================================================
	function buildPubDate(cmspage) {
		var pubDate = cmspage.OriginalPublicationDate;

		return convertDate(pubDate);
	}

	// REFORMATTING PUBLICATION DATE
	//====================================================
	function convertDate(date) {
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			splitDate = date.split('-');
		return months[parseInt(splitDate[1])-1] + " " + splitDate[2] + ", " + splitDate[0];
	}	
})(jQuery);

$(window).on('load', function() {
	moveFeaturedCta();
	filterButton();
});

$(window).on('resize', function() {
// Runs as soon as the window is resized
	if (isAndroidDevice && $('input').is(':focus')) {
		//do nothing if input has focus
	} else {
		moveFeaturedCta();
		changesOnResize();
	}
});

$window.on('orientationchange', function () {
// Runs as soon as the screen orientation is changed
	moveFeaturedCta();
	changesOnResize();
});